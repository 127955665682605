import React from "react";
import TabelleStore from "./TabelleStore";
import {observer} from "mobx-react";
import {AnzahlSpieltageNachWochentag, Wochentag} from "./TabelleTypes";

interface TabelleProps {
    store: TabelleStore;
}

@observer
export default class Tabelle extends React.Component<TabelleProps> {

    render() {
        const saison = this.props.store.selectedSaison;
        if (!saison) {
            return <em>Keine Saison ausgewählt</em>;
        }

        if (this.props.store.loadingTabelle) {
            return <em>Lade Tabelle für {saison.jahr}</em>;
        }

        const tabelle = this.props.store.selectedTabelle;
        if (!tabelle) {
            return <em>Keine Tabelle gefunden für {saison.jahr}</em>;
        }

        const distinctWochentage = [...tabelle.spieltageNachWochentag]
            .map((it) => it.wochentag)
            .filter((value, index, self) => {
                return self.findIndex((it) => it.wochentag === value.wochentag) === index
            });

        return <>
            <h4>Tabelle für die Saison {saison.jahr} nach {tabelle.spieltageGesamt} Spieltagen</h4>
            <table className="table table-condensed table-striped table-bordered training-table sortable">
            <colgroup>
                <col style={{width: '40px'}}/>
                <col/>
                <col style={{width: '60px'}}/>
                {distinctWochentage.map((key, index) => <col key={`col-tn-${index}`} style={{width: '60px'}}/>)}
                <col style={{width: '60px'}}/>
                {distinctWochentage.map((key, index) => <col key={`col-td-${index}`} style={{width: '60px'}}/>)}
            </colgroup>

            <thead>
            <tr>
                <th rowSpan={2}>#</th>
                <th rowSpan={2} data-defaultsign="AZ">Spieler</th>
                <th colSpan={distinctWochentage.length + 1}>Teilnahmen</th>
                <th colSpan={distinctWochentage.length + 1}>Trikotdienst</th>
            </tr>

            <tr>
                <th>Ges</th>
                {distinctWochentage.map((wochentag) => <th key={`th-tn-${wochentag.wochentag}`}>{wochentag.wochentagLabelKurz}</th>)}

                <th>Ges</th>
                {distinctWochentage.map((wochentag) => <th key={`th-td-${wochentag.wochentag}`}>{wochentag.wochentagLabelKurz}</th>)}
            </tr>
            </thead>

            <tbody>
            {tabelle.tabelle.map((platz, index) => {
                return <tr key={`tabellenplatz-${index + 1}`}>
                    <th className="text-center">{index + 1}</th>
                    <th>{platz.spieler.name}</th>

                    <td className="text-right">{platz.teilnahmenGesamt}</td>
                    {
                        this.getWochentagAnzahl(distinctWochentage, platz.teilnahmenNachWochentag)
                            .map((teilnahmen, index) => <td key={`tabellenplatz-${index + 1}-tn-${teilnahmen.wochentag.wochentag}`} className="text-right">{teilnahmen.anzahlSpiele}</td>)
                    }

                    <td className="text-right">{platz.trikotDienstGesamt}</td>
                    {
                        this.getWochentagAnzahl(distinctWochentage, platz.trikotdienstNachWochentag)
                            .map((trikotdienst, index) => <td key={`tabellenplatz-${index + 1}-tn-${trikotdienst.wochentag.wochentag}`} className="text-right">{trikotdienst.anzahlSpiele}</td>)
                    }
                </tr>
            })}
            </tbody>

        </table></>;
    }

    private getWochentagAnzahl(distinctWochentage: Array<Wochentag>, spieltageNachWochentag: Array<AnzahlSpieltageNachWochentag>): Array<AnzahlSpieltageNachWochentag> {
        return distinctWochentage.map((wochentag) => {
            const anzahlSpieleNachWochentag = spieltageNachWochentag.find((it) => it.wochentag.wochentag === wochentag.wochentag);
            return anzahlSpieleNachWochentag ? anzahlSpieleNachWochentag : {
                wochentag: wochentag,
                anzahlSpiele: 0
            };
        });
    }
}