import {action, makeObservable, observable} from "mobx";
import {Saison, Tabellenstand} from "./TabelleTypes";

export default class TabelleStore {

    @observable loadingSaisons: boolean = true;
    @observable saisons: Array<Saison> = [];
    @observable selectedSaison: Saison | null = null;

    @observable loadingTabelle: boolean = false;
    @observable selectedTabelle: Tabellenstand | null = null;
    private saisonZuTabelle: Map<number, Tabellenstand> = new Map<number, Tabellenstand>();

    constructor(baseUrl: string) {
        makeObservable(this);

        fetch(baseUrl)
            .then((response) => response.json())
            .then((data: Array<Saison>) => this.setSaisons(data));
    }

    @action
    private setSaisons(saisons: Array<Saison>) {
        this.saisons.push(...saisons);

        if (saisons.length > 0) {
            this.selectSaison(saisons[saisons.length - 1]);
        }

        this.loadingSaisons = false;
    }

    @action
    selectSaison(saison: Saison) {
        this.selectedSaison = saison;
        this.loadTabelle(saison);
    }

    @action
    private loadTabelle(saison: Saison) {
        this.loadingTabelle = true;
        console.log("Lade Tabelle");

        if (this.saisonZuTabelle.has(saison.jahr)) {
            this.selectTabelle(this.saisonZuTabelle.get(saison.jahr)!!);
        } else {
            console.log("Lade Tabelle aus", saison.tabelleUrl);
            fetch(saison.tabelleUrl)
                .then((response) => response.json())
                .then((tabelle: Tabellenstand) => this.setTabelle(saison, tabelle))
        }
    }

    @action
    private setTabelle(saison: Saison, tabelle: Tabellenstand) {
        this.saisonZuTabelle.set(saison.jahr, tabelle);
        this.selectTabelle(tabelle);
    }

    @action
    private selectTabelle(tabelle: Tabellenstand) {
        this.selectedTabelle = tabelle;
        this.loadingTabelle = false;
    }
}