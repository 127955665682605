import React from "react";
import {observer} from "mobx-react";
import TabelleStore from "./TabelleStore";
import {Saison} from "./TabelleTypes";
import Tabelle from "./Tabelle";

interface SaisonTabellenProps {
    store: TabelleStore;
}

@observer
export default class SaisonTabellen extends React.Component<SaisonTabellenProps> {

    render() {
        if (this.props.store.loadingSaisons) {
            return <em>Lade Saisons</em>;
        }

        if (this.props.store.saisons.length === 0) {
            return <em>Keine Saisons vorhanden</em>;
        }

        return <>
            <ul className="nav nav-tabs" role="tablist">
                {this.props.store.saisons.map((saison) => {
                    const activeClass = saison.jahr === this.props.store.selectedSaison?.jahr ? "active" : "";
                    return <li key={saison.jahr} role="presentation" className={activeClass}>
                        <a href="#" onClick={(e) => {
                            this.selectSaison(saison);
                            e.preventDefault();
                        }}>{saison.jahr}</a>
                    </li>
                })}
            </ul>

            <div className="tab-content">
                <Tabelle store={this.props.store} />
            </div>
        </>;
    }

    private selectSaison(saison: Saison) {
        this.props.store.selectSaison(saison);
    }
}